<template>
	<div style="width:100%; margin:0 auto;">
		<div class="topNavCon">
			<div class="topNavCon_left">
				<div class="logoCon" style="">
					<div class="logo-img-con">
						<!-- <img src="../../assets/images/logo_small.png" width="50" /> -->
						<!-- <img src="../../assets/logo_en.png" width="150" /> -->
						<img :src="$store.state.sysShow.logoInfo.logo" width="150" height="auto" />
					</div>
					<div>
						<!-- <i class="el-icon-s-fold"></i> -->
						<el-link style="font-size: 24px;color:#fff;" :underline="false"
							:icon="menuCollapse?'el-icon-s-unfold':'el-icon-s-fold'" @click="toggleCollapse"></el-link>
						<!-- <el-button icon="el-icon-s-fold"></el-button> -->
					</div>

					<div>
						<el-button type="primary" plain round size="small" icon="el-icon-search" @click="openSearch()"
							style="vertical-align: middle;"></el-button>
					</div>

					<!-- <h3> -->
					<!-- 互易仓 -->
					<!-- HYTX <br /> WMS -->
					<!-- {{$t("meta.互易仓")}} -->
					<!-- <br />{{$t('i18nn_5920542fc20b3263')}} -->
					<!-- </h3> -->
				</div>
			</div>
			<div class="topNavCon_center">
				<div class="topHeadNav">
					<div class="topBarNav" style="">
						<!-- <el-menu
              :default-active="$route.matched[1] ? $route.matched[1].name : ''"
              mode="horizontal"
              :router="true"
              @select="handleSelect"
              v-bind:style="{ width: menusList.length * 105 + 'px', minWidth: '100%' }"
            >
              <el-menu-item
                v-for="(item, index) in menusList"
                :key="index"
                :route="{ name: item.menuDtoList && item.menuDtoList.length > 0 ? item.menuDtoList[0].key : item.key }"
                :index="item.key"
                v-if="0 == item.isShow"
              >
                {{ item.menuName }}
              </el-menu-item>
            </el-menu> -->


						<div class="historyTabCon" v-if="'0'!=themeConfig.showTags">
							<ul class>
								<li v-for="(item, index) in historyTabData" :key="index">
									<!-- <transition name="el-zoom-in-center"> -->
									<el-tag :closable="!item.canClose" size="" :effect="'plain'" :hit="true" @click="selTab(item)"
										@close="removeTab(index)" :type="editableTabsValue === item.routeName ? 'primary' : 'info'">
										<!-- {{ item.title }} -->
										{{ 'en'==$i18n.locale&&item.titleEn?item.titleEn:item.title }}
									</el-tag>
									<!-- </transition> -->
								</li>
							</ul>
							<!-- <el-tabs v-model="editableTabsValue" type="card" @tab-click="handleClick">
							    <el-tab-pane v-for="(item, index) in historyTabData" :key="index" :label="item.title" :name="routeName"></el-tab-pane>
							  </el-tabs> -->
						</div>
					</div>
				</div>
			</div>



			<div class="topNavCon_right">
				<div class="topHeaderRiCon">
					<div class="topHeaderLang" style="margin-top:15px;">

						<el-button type="primary" size="small" icon="el-icon-position" @click="$router.push({name:'PalletMark'})"
							style="width:100px;overflow:hidden;">{{$t('i18nn_1d5ba91957adcff9')}}</el-button>

						<el-button type="success" size="small" icon="el-icon-plus" @click="toFeeAddPage"
							style="margin-right:20px;width:100px;overflow:hidden;">{{$t('i18nn_9527abe40244bd22')}}</el-button>

						<el-radio-group v-model="activeLang" size="small" @change="switchLang" style="margin-right:10px;">
							<el-radio-button :label="'cn'">中&nbsp;文</el-radio-button>
							<el-radio-button :label="'en'">EN</el-radio-button>
						</el-radio-group>

						<!-- {{ $t('topBar.language') }}: -->
						<!-- <el-select v-model="activeLang" size="small" :placeholder="$t('FormMsg.Please_select')" @change="switchLang" style="width: 100px;">
							<el-option :label="$t('topBar.Chinese')" :value="'cn'"></el-option>
							<el-option :label="$t('topBar.English')" :value="'en'"></el-option>
						</el-select> -->
						<!-- <el-select v-model="activeLang" size="small" :placeholder="'Language'" @change="switchLang"
							style="width: 80px;">
							<el-option :label="$t('9fcdcb3a067903d8')" :value="'cn'"></el-option>
							<el-option :label="'EN'" :value="'en'"></el-option>
						</el-select> -->
					</div>

					<div class="topHeaderRi" style="">
						<el-dropdown :show-timeout="0" trigger="click">
							<span class="el-dropdown-link">
								<span class="nameLink" v-if="$store.getters.getUserInfo">{{ $store.getters.getUserInfo.account }}</span>
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown" class="topbarDropdown">

								<a href="javascript:void(0)" @click="openModifyPassword()">
									<el-dropdown-item>
										<i class="el-icon-edit"></i>
										<span>{{$t('i18nn_dae8d92a749abc4e')}}</span>
									</el-dropdown-item>
								</a>

								<a href="javascript:void(0)" @click="themeAction()">
									<el-dropdown-item>
										<i class="el-icon-brush"></i>
										<span>
											{{$t('i18nn_468267e2f309f146')}}
										</span>
									</el-dropdown-item>
								</a>

								<a href="javascript:void(0)" @click="loginOutAction()">
									<el-dropdown-item>
										<i class="el-icon-right"></i>
										<span>
											{{$t('b4bced00356095dc')}}
										</span>
									</el-dropdown-item>
								</a>

							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>

			</div>
		</div>

		<!-- 修改密码 -->
		<dialogModifyPassword :openTime="modifyPassOpenTime"></dialogModifyPassword>

		<!-- 模板 -->
		<ThemeConfig :openTime="ThemeConfigOpenTime"></ThemeConfig>

		<!-- 菜单搜索框 -->
		<SearchMenus :openTime="SearchMenusOpenTime"></SearchMenus>

	</div>
	<!-- </el-header> -->
	<!-- 内容区域 -->
	<!-- <el-container class="frameCon" :style="{height:contenHeight-120+'px'}"> -->
	<!-- 路由内容区域 -->
	<!-- <router-view/> -->
	<!-- </el-container> -->
	<!-- <el-footer class="frameFooter">&copy;&nbsp;&nbsp;永飞&nbsp;&nbsp;版权所有</el-footer> -->
	<!-- </div> -->
</template>

<script>
	import {
		getStorage,
		history_Tab_Data
	} from '@/utils/Storage.js';

	import {
		mapGetters
	} from 'vuex';

	import dialogModifyPassword from '@/layout/Header/dialogModifyPassword.vue';

	import ThemeConfig from '@/layout/Header/ThemeConfig.vue';
	import SearchMenus from '@/layout/Header/SearchMenus.vue';
	export default {
		name: 'Frame',
		components: {
			ThemeConfig,
			SearchMenus,
			dialogModifyPassword
		},
		data() {
			return {
				// menusList: [],
				editableTabsValue: this.$route.name,
				activeLang: this.$i18n.locale,
				// isShowFeeBtn: false,//是否显示财务按钮
				ThemeConfigOpenTime: "",
				SearchMenusOpenTime: "",
				modifyPassOpenTime: ""
			};
		},
		watch: {
			$route(to, from) {
				this.editableTabsValue = this.$route.name;
			}
		},
		computed: {
			...mapGetters(['historyTabData']),
			...mapGetters({ // 对象形式下 改变映射
				themeConfig: "getThemeConfig"
			}),
			...mapGetters({ // 对象形式下 改变映射
				menuCollapse: "getMenuCollapse"
			}),
			routeArr() {
				return this.$route.matched;
			}
		},
		created() {
			this.init();
			this.initHisTab();
		},
		mounted() {},
		methods: {

			loginOutAction() {
				this.$confirm('确定退出登录吗?', this.$t('daaaeb1b7b22b126'), {


					type: 'warning'
				}).then(() => {
					this.loginOut();
				}).catch(() => {

				});
			},
			loginOut() {
				// this.$router.repeach({ name: 'Login' });
				console.log('loginOut');
				//登出
				this.$http
					.post(this.$urlConfig.HyLoginOut, {})
					.then(response => {
						console.log(this.$t('i18nn_74708f3a8699305a'), response);
					})
					.catch(err => {
						console.error(err);
					});
				this.$store.dispatch('USER_SIGNOUT');
				this.$store.dispatch('MENUS_SIGNOUT');
				this.$router.replace({
					name: 'Login'
				});
			},

			//历史记录标签
			removeTab(index) {
				console.log(index);
				this.$store.dispatch('del_history_Tab_Data', index);
			},
			selTab(item) {
				console.log(item);
				if (this.$route.name === item.routeName) { //点击当前一级菜单
					console.log(this.$route.name, item.routeName);
					return;
				}

				this.$router.push({
					name: item.routeName,
					query: item.query
				});
			},
			// handleClick(item) {
			//     console.log(item);
			// 	this.selTab({
			// 		routeName:editableTabsValue
			// 	});
			//   },

			init() {
				//是否存在登录信息,不存在登出
				if (!this.$store.getters.getUserInfo || !this.$store.getters.getUserInfo.account) {
					this.loginOut();
					return;
				}
				//设置顶部菜单
				// this.menusList = this.$store.getters.getMenusInfo;
				// console.log('this.menusList', this.menusList);
				// if (this.$store.getters.getMenusInfo) {
				//   this.menusList = this.$store.getters.getMenusInfo;
				// } else {
				//   this.getPremissMenu();
				// }
				//设置是否显示财务按钮
				// let menusList = this.$store.getters.getMenusInfo;
				// this.isShowFeeBtn = true;
			},
			initHisTab() {
				// let history_Tab_Data = localStorage.getItem('history_Tab_Data');
				let history_Tab_Data_val = getStorage(history_Tab_Data);

				if (!!this.historyTabData && !!this.historyTabData.length > 0) {} else if (!!history_Tab_Data_val &&
					'undefined' !=
					history_Tab_Data_val && '[]' != history_Tab_Data_val) {
					let data = JSON.parse(history_Tab_Data_val);
					// console.log('history_Tab_Data data',data);
					this.$store.dispatch('set_history_Tab_Data', data);
				}
			},
			//切换语言
			switchLang(lang) {
				this.$i18n.locale = lang;
				this.$store.dispatch('UPDATE_LANG', lang);
				this.$message.success(this.$t('topBar.switchLangSuccess'));
				this.$alert(this.$t('tips.changeLangMsg'), this.$t('tips.tipsTitle'), {
					// confirmButtonText: this.$t('204ffab8a6e01870'),
					type: 'warning',
					callback: action => {
						window.location.reload();
					}
				});
			},
			//去财务记录创建页
			toFeeAddPage() {
				this.$router.push({
					name: 'WhFinanceAdd'
				});
			},
			//主题
			themeAction() {
				console.log('themeAction');
				this.ThemeConfigOpenTime = new Date().getTime();
			},
			toggleCollapse() {
				if (this.menuCollapse) {
					// this.menuCollapse = false;
					this.$store.dispatch('UPDATE_MENU_COLLAPSE', false);
				} else {
					this.$store.dispatch('UPDATE_MENU_COLLAPSE', true);
				}
			},
			//菜单搜索
			openSearch() {
				this.SearchMenusOpenTime = new Date().getTime();
			},

			//打开修改密码
			openModifyPassword() {
				this.modifyPassOpenTime = new Date().getTime();
			},

			// handleSelect(keyPar, keyPath) {
			//   // console.log(key, keyPath);

			//   console.log('handleSelect', keyPar, keyPath);

			//   //是否存在该路由 存在则读取query
			//   // let hasName = false;
			//   // this.historyTabData.forEach(item => {
			//   //     if(item.routeName===key){
			//   //         this.$router.push({name:key,query: item.query});
			//   //         hasName = true;
			//   //     }
			//   // });

			//   // //不存在则添加 query查询
			//   // if(hasName===false){
			//   //     this.$router.push({name:key,query: {}});
			//   // }

			//   // this.$store.dispatch('add_history_Tab_Data', {
			//   //     title: key,
			//   //     routeName: key,
			//   //     query: {}
			//   // });

			//   // let key = '';

			//   let selObj = this.menusList.find(item => {
			//     return item.key == keyPar;
			//   });
			//   let selFirstObj = selObj.menuDtoList[0];
			//   let title = selFirstObj.menuName;
			//   let key = selFirstObj.key;
			//   this.$store.dispatch('add_history_Tab_Data', {
			//     title: title,
			//     routeName: key,
			//     query: {}
			//   });
			// },
			// toPage(pageName) {
			//   this.$router.push({ name: pageName });
			// },
			//获得用户权限菜单
			// getPremissMenu() {
			//   console.log(this);
			//   this.$http
			//     .get(this.$urlConfig.HyAdminMenuAllPage)
			//     .then(response => {
			//       if (response.data && '200' === response.data.code) {
			//         console.log(this.$t('i18nn_48fea1870aa235fd'));
			//         this.loginSuccess(response.data);
			//       } else {
			//         this.$message.warning(response.data.msg ? response.data.msg : this.$t('i18nn_725d750832726cc3'));
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       this.$message.error('获得用户权限菜单，请求失败！');
			//     });
			// },
			///获得用户权限菜单成功
			// loginSuccess(data) {
			//   this.$message.success(this.$t('i18nn_b125fa6fe5308e6a'));
			//   this.$store.commit('UPDATE_USERINFO', data.user);
			//   this.$store.dispatch('UPDATE_MENUS', data.menus);
			//   let menusInfo = this.$store.getters.getMenusInfo;
			//   let menusInfoKey = this.$store.getters.getMenusInfoKey;
			//   if (menusInfo && menusInfoKey && menusInfo.length > 0 && menusInfoKey.length > 0) {
			//     // this.$router.push({name:menusInfoKey[0]});
			//     this.menusList = this.$store.getters.getMenusInfo;
			//   } else {
			//     this.$message.warning(this.$t('i18nn_8f0b9b3e6ac16f1b'));
			//   }
			// }
			//获得用户权限菜单
			// getPremissMenu() {
			//   console.log(this);
			//   // let that = this;
			//   this.loading = true;
			//   this.$http.get(this.$urlConfig.HyAdminMenuAllPage)
			//     .then(response => {
			//       // console.log(response);
			//       this.loading = false;
			//       if (response.data && '200' === response.data.code) {
			//         console.log(this.$t('i18nn_48fea1870aa235fd'));
			//         this.menusList = this.filterMenusData(response.data.menus);
			//         console.log('this.menusList', this.menusList);
			//         // this.$router.push({name:'Frame'});
			//         this.loginSuccess(response.data);
			//       } else {
			//         this.$message.warning(response.data.msg ? response.data.msg : this.$t('i18nn_725d750832726cc3'));
			//       }

			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       this.loading = false;
			//       this.$message.error('登录请求失败！');
			//     });
			// },
			// //处理菜单的显示
			// filterMenusData(menusParm) {
			//   let menusInfo = [];
			//   console.log('menusParm', menusParm);
			//   for (let i = 0, ln = menusParm.length; i < ln; i++) {
			//     let oneObj = menusParm[i];
			//     let menuDtoList = [];
			//     if (!oneObj || oneObj.request.indexOf("#") > -1 || oneObj.request.indexOf(".html") > -1) {
			//       continue;
			//     }
			//     for (let j = 0, lnn = oneObj.menuDtoList.length; j < lnn; j++) {
			//       let oneObj2 = oneObj.menuDtoList[j];
			//       if (!oneObj2 || oneObj2.request.indexOf("#") > -1 || oneObj2.request.indexOf(".html") > -1) {
			//         continue;
			//       }
			//       let requestArr = oneObj2.request.split('/');

			//       if (requestArr.length <= 0) {
			//         continue;
			//       }

			//       menuDtoList.push({
			//         request: oneObj2.request,
			//         menuName: oneObj2.menuName,
			//         url: oneObj2.request,
			//         key: requestArr[requestArr.length - 1]
			//       })
			//     }
			//     let requestArr = oneObj.request.split('/');
			//     if (requestArr.length <= 0) {
			//       continue;
			//     }
			//     let oneRouter = {
			//       request: oneObj.request,
			//       menuName: oneObj.menuName,
			//       url: oneObj.request,
			//       key: requestArr[requestArr.length - 1]
			//     };
			//     if (menuDtoList && menuDtoList.length > 0) {
			//       oneRouter.menuDtoList = menuDtoList;
			//     }
			//     menusInfo.push(oneRouter)
			//   }
			//   return menusInfo;
			// },
			// ///获得用户权限菜单成功
			// loginSuccess(data) {
			//   this.$message.success(this.$t('i18nn_b125fa6fe5308e6a'));
			//   this.$store.commit('UPDATE_USERINFO', data.user);
			//   this.$store.dispatch('UPDATE_MENUS', data.menus);
			//   let menusInfo = this.$store.getters.getMenusInfo;
			//   console.log('Frame menus', menusInfo);
			//   if (menusInfo) {
			//     let menus = menusInfo;
			//     console.log("menus", menus);
			//     if (menus) {
			//       console.log(this.$router.options);
			//       this.$router.options.routes.push(menus);
			//       this.$router.addRoutes(this.$router.options.routes); //调用addRoutes添加路由
			//       // this.$router.addRoutes(menus);
			//       this.$router.push({ name: 'Frame' });
			//     } else {
			//       console.log("menus NULL");
			//     }
			//   } else {
			//     console.log("menusInfo NULL", menusInfo);
			//   }
			// },
		}
	};
</script>
<style scope lang="less">


</style>